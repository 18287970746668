import * as React from 'react';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress, {
//   circularProgressClasses,
// } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LoaderImg from '../images/loader3.svg'
// import Avatar from '@mui/material/Avatar';
export default function SimpleBackdrop({ show,margin }) {
  // const [open, setOpen] = React.useState(true);
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  return (
    <Box sx={{margin:`${margin}%`,display:'flex',justifyContent:'center',alignItems:'center'}}  >
      {show && <img alt="loader" src={LoaderImg} />}
    </Box>
  );
}
