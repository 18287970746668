import React, { useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { TextField, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import Header from '../layouts/dashboard/header';
import LoadingButton from '@mui/lab/LoadingButton';
import parse from 'html-react-parser';
export default function Login() {
    const navigate = useNavigate();
    const [btnLoad, setBtnLoad] = useState(false)
    const [regx, setRegx] = useState();
    const [error, setError] = useState('');
    const [helptext, setHelptext] = useState('');
    const [exit_id, setExitID] = useState();
    const [exit_date, setExitDate] = useState('');
    const [store, setStore] = useState('');
    const [storeName, setStoreName] = useState('');
    const [user_id, setUserID] = useState();
    const [askNew, setAskNew] = useState('');
    
   const callApi= (exit_id) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(`${process.env.REACT_APP_SITE_URL}?exit_id=${exit_id}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if ('data' in data) {
                    setExitID(data.data.exit_id)
                    setStore(data.data.store)
                    setUserID(data.data.user_id)
                    sessionStorage.setItem("exit_id",data.data.exit_id);
                    sessionStorage.setItem("store",data.data.store);
                    sessionStorage.setItem("user_id",data.data.user_id);
                    if(data.data.stage === 1){
                        navigate('/order-amount')
                    }
                    if (data.data.stage === 1) {
                        navigate('/order-amount')
                    } else if (data.data.stage === 2) {
                        navigate('/user-type')
                    }  else if (data.data.stage === 3 || data.data.stage === 4) {
                        navigate('/thankyou')
                    } 

                } 
            });
   }
    const callRegx= (store_name, exitId, userId, exitDate) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(`${process.env.REACT_APP_SITE_URL}/regex?name=${store_name}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.data.length > 0) {
                    let new_data = data.data[0];
                    let reg =new RegExp(data.data[0].regx);
                    setRegx(reg)
                    setError(new_data.error)
                    setHelptext('exp. : ' + new_data.help_text);
                    setExitID(exitId)
                    setStore(new_data.name)
                    setUserID(userId)
                    setExitDate(exitDate)
                    let store_naam = new_data.name; 
                    if(store_naam.includes('_')){
                        store_naam = store_naam.replace(/_/g,' ')
                    }
                    setStoreName(store_naam)
                    setAskNew(new_data.new_user_screen)
                    callApi(exitId);
                } else {
                    navigate('/page-not-found')
                }
            });
    }
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        let exitId = urlParams.get('exit_id');
        let store_name = urlParams.get('store');
        let userId = urlParams.get('user_id');
        let exitDate = urlParams.get('exit_date');
        if(exitId !== '' && exitId !== null && store_name !== '' && store_name !== null && userId !== '' && userId !== null) {
            callRegx(store_name.toLowerCase(), exitId, userId, exitDate)
        } else {
            navigate('/page-not-found')
        }
        
        // eslint-disable-next-line
    }, [])
 
    const LoginFormSchema = Yup.object().shape({
        order_id: Yup.string().required('Order ID is required.').matches(regx,error),
    })
    const formik = useFormik({
        initialValues: {
            order_id: '',
            exit_id: exit_id,
            user_id: user_id,
            store: store,
            exit_date: exit_date,
            stage: 1,
        },
        enableReinitialize: true,
        validationSchema: LoginFormSchema,
        onSubmit: (initialValues, {setErrors}) => {
            setBtnLoad(true)
            if(initialValues.store === 'myntra'){
                if(initialValues.order_id.includes(' ')){
                    initialValues.order_id = initialValues.order_id.split(' ').join('-')
                    let slice = initialValues.order_id.slice(0, -7);
                    let slice2 = initialValues.order_id.slice(15);
                    initialValues.order_id = slice+'-'+slice2
                }
            }

            if(initialValues.store === 'ajio'){
                initialValues.order_id = initialValues.order_id.charAt(0).toUpperCase() + initialValues.order_id.charAt(1).toUpperCase() + initialValues.order_id.slice(2)
            }
            if(initialValues.store === 'nykaa' || initialValues.store === 'nykaa_fashion'){
                initialValues.order_id = initialValues.order_id.charAt(0).toUpperCase() + initialValues.order_id.charAt(1).toUpperCase() + initialValues.order_id.charAt(2).toUpperCase() + initialValues.order_id.slice(3)
            }
            if(initialValues.store === 'beardo'){
                if(initialValues.order_id.includes('c') || initialValues.order_id.includes('g')){
                    initialValues.order_id = initialValues.order_id.charAt(0).toUpperCase() + initialValues.order_id.slice(1)
                }
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(initialValues)
            };
            fetch(`${process.env.REACT_APP_SITE_URL}`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setBtnLoad(false)
                    if ('data' in data) {
                        sessionStorage.setItem("exit_id",exit_id);
                        sessionStorage.setItem("store",store);
                        sessionStorage.setItem("user_id",user_id);
                        sessionStorage.setItem("user_id",user_id);
                        sessionStorage.setItem("ask_new",askNew);
                        sessionStorage.setItem("store_name",storeName);
                        navigate(`/order-amount`)
                    }  else {
                        let err = 'Uh-oh, it looks like a query for this order ID has already been raised.';
                        setErrors({order_id: err.toString()})
                    }
                });
        }
    });
   
    const { errors, touched, handleSubmit, setFieldValue } = formik;
    
    return (
        
        <Box noValidate autoComplete="off" >
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Header title="" paper="yes" backArrow="no" />
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={3} sx={{ paddingX: '20px', marginTop:'20px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography  sx={{color:'#000000', textAlign:'center', fontSize:'20px', fontWeight:600}}>Enter your <span style={{textTransform:'capitalize'}}>{storeName}</span> Order ID below</Typography><br /><br />
                                    <Typography  sx={{color:'#000000', textAlign:'center', fontSize:'16px'}}>Please enter your order ID without any spaces or symbols like ₹ or #</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        InputLabelProps={{ style: { color: '#7a7070' } }}
                                        label="Enter Your Order ID"
                                        // {...getFieldProps('order_id')}
                                        onChange={(e)=>setFieldValue('order_id',e.target.value.trim())}
                                        sx={{ color:'#000000', width:'100%', }}
                                        error={Boolean(touched.order_id && errors.order_id)}
                                        helperText={(touched.order_id && errors?.order_id && parse(errors?.order_id) ) || helptext }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} mt={2}></Grid>
                                <Grid item xs={12} sm={12} md={12} mt={2} sx={{textAlign:'center'}}>
                                <LoadingButton
                                    sx={{ width: '60%', textTransform: 'capitalize', borderRadius: '50px', padding: '10px', background:'#F07431' }}
                                    // color="secondary"
                                    text="Next"
                                    variant="contained"
                                    type="submit"
                                    loading={btnLoad}
                                    size="small"
                                    // onClick={()=>clickFunc ? clickFunc : ''}
                                >
                                    Next
                                </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            </Grid>
        </Box>
    );

}
