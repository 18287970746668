import React, { useEffect } from "react";
import { Box, Typography, } from '@mui/material';
import Grid from '@mui/material/Grid';
import {  useNavigate } from 'react-router-dom';
import Header from '../layouts/dashboard/header';
export default function Login() {
    const navigate = useNavigate();

    const callApi = (exit_id) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(`${process.env.REACT_APP_SITE_URL}?exit_id=${exit_id}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if ('data' in data) {
                    if (data.data.stage === 1) {
                        navigate(`/order-amount`)
                    }  else if (data.data.stage === 3 || data.data.stage === 4) {
                        navigate(`/thankyou`)
                    } 
                } else {
                    navigate('/page-not-found')
                }
            });
    }
    const callRegx= (store_name, exitId, userId) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(`${process.env.REACT_APP_SITE_URL}/regex?name=${store_name}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.data.length > 0) {
                    // let new_data = data.data[0];
                    callApi(exitId)
                } else {
                    navigate('/page-not-found')
                }
            });
    }
    useEffect(() => {
        let exitId = sessionStorage.getItem('exit_id');
        let store_name = sessionStorage.getItem('store');
        let userId = sessionStorage.getItem('user_id');
        if (exitId !== '' && exitId !== null && store_name !== '' && store_name !== null && userId !== '' && userId !== null) {
            callRegx(store_name, exitId, userId)
        } else {
            navigate('/page-not-found')
        }
        // eslint-disable-next-line
    }, [])
    return (
        <Box noValidate autoComplete="off" >
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Header title="" paper="yes" backArrow="no" />
                    <Grid container spacing={3} sx={{ paddingX: '20px', marginTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                        {/* <Button
                                sx={{ textTransform: 'capitalize',   color:'#F07431',  borderColor:'#F07431'}}
                                text="Next"
                                variant="outlined"
                                type="submit"
                                onClick={handleNoButton}
                            >
                                Back
                        </Button> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}></Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}></Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}></Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography sx={{ color: '#000000', textAlign: 'center', fontSize: '16px' }}>Thank you for submitting the details of your order. Our team shall update you on your Cashback within next 72 hours.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            </Grid>
        </Box>
    );

}
