import React, { useState, useEffect } from 'react';

const NoInternetConnection = (props) => {
    // state variable holds the state of the internet connection
    const [isOnline, setOnline] = useState(true);

    // On initization set the isOnline state.
    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    // event listeners to update the state 
    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    // if user is online, return the child component else return a custom component
    if (isOnline) {
        return (
            props.children
        )
    } else {
        return (<h3 style={{margin:'2rem',textAlign:'center',transform: 'translate(0px, 8rem)'}}>Check your Internet connection and try again.</h3>)
    }
}

export default NoInternetConnection;