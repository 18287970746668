import React from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box} from '@mui/material';
const darkTheme = createTheme({ palette: { mode: 'dark' } });

export default function Header(props) {
  
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Box
            sx={{
              bgcolor: '#2F3C97',
              display: 'grid',
              width: '100%',
              height: '15%',
              
            }}
            justifyContent="center"
          >
          <img src="https://asset20.ckassets.com/wp/wp-content/uploads/sites/37/2020/04/5e8dbdd7383b9.png" alt=""  style={{paddingTop:'15px',paddingBottom:'10px',}}/>
        </Box>
        
      </ThemeProvider >
      </>
  );
}
