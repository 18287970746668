// import logo from './logo.svg';
import React, { useEffect } from 'react'
import './App.css';
import Router from './routes';
import UserState from './contexts/UserState'
import NoInternetConnection from './component/NoInternetConnection'
// import { withRouter, useHistory } from 'react-router-dom';
// import Layout from './layouts/dashboard/index';

function App() {
  // const history = useHistory()
  useEffect(() => {
    // history.listen(() => {
    if (window.swUpdateReady) {
      window.swUpdateReady = false;
      window.stop();
      window.location.reload();
    }
    // })

  }, [])

  return (
    <NoInternetConnection>
      <UserState>
        <Router />
      </UserState>
    </NoInternetConnection>

  );
}

export default App;
