import React, { useEffect,  useState } from "react";
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import Header from '../layouts/dashboard/header';
import LoadingButton from '@mui/lab/LoadingButton';

export default function Login() {
    const navigate = useNavigate();
    const [yesBtnLoad, setYesBtnLoad] = useState(false)
    const [noBtnLoad, setNoBtnLoad] = useState(false)
    const [exit_id, setExitID] = useState();
    const [store, setStore] = useState('');
    const [user_id, setUserID] = useState();
    const [storeName, setStoreName] = useState('');

   
    const callApi = (exit_id) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(`${process.env.REACT_APP_SITE_URL}?exit_id=${exit_id}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if ('data' in data) {
                    setExitID(data.data.exit_id)
                    setStore(data.data.store)
                    setUserID(data.data.user_id)
                    let store_naam = data.data.name; 
                    if(store_naam.includes('_')){
                        store_naam = store_naam.replace(/_/g,' ')
                    }
                    setStoreName(store_naam)
                    if (data.data.stage === 1) {
                        navigate(`/order-amount`)
                    }  else if (data.data.stage === 3 || data.data.stage === 4) {
                        navigate(`/thankyou`)
                    } 

                } else {
                    navigate('/page-not-found')
                }
            });
    }
    const callRegx= (store_name, exitId, userId) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(`${process.env.REACT_APP_SITE_URL}/regex?name=${store_name}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.data.length > 0) {
                    setExitID(exitId)
                    setStore(store_name)
                    setUserID(userId)
                    let store_naam = store_name; 
                    if(store_naam.includes('_')){
                        store_naam = store_naam.replace(/_/g,' ')
                    }
                    setStoreName(store_naam)

                    callApi(exitId);
                } else {
                    navigate('/page-not-found')
                }
            });
    }
    useEffect(() => {
        let exitId = sessionStorage.getItem('exit_id');
        let store_name = sessionStorage.getItem('store');
        let userId = sessionStorage.getItem('user_id');
        let ask_new = sessionStorage.getItem('ask_new');
        if (exitId !== '' && exitId !== null && store_name !== '' && store_name !== null && userId !== '' && userId !== null) {
            if(ask_new === 'Yes'){
                callRegx(store_name, exitId, userId)
            } else{
                navigate(`/thankyou`)
            }
        } else {
            navigate('/page-not-found')
        }
        // eslint-disable-next-line
    }, [])

    const formAPI = (status) => {
        const initialValues = {
            new_user : status,
            exit_id: exit_id,
            user_id: user_id,
            stage: 3,
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(initialValues)
        };
        fetch(`${process.env.REACT_APP_SITE_URL}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setYesBtnLoad(false)
                setNoBtnLoad(false)
                if ('data' in data) {
                    navigate(`/thankyou`)
                }
            });
        
    }
    const handleYesButton = () => {
        setYesBtnLoad(true)
        setNoBtnLoad(false)
        formAPI('Yes');
    }
    const handleNoButton = () => {
        setNoBtnLoad(true)
        setYesBtnLoad(false)
        formAPI('No')

    }
    return (
        <Box noValidate autoComplete="off" >
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Header title="" paper="yes" backArrow="no" />
                    <Grid container spacing={3} sx={{ paddingX: '20px', marginTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography sx={{ color: '#000000', textAlign: 'center', fontSize: '20px', fontWeight: 600 }}>Have you ever shopped on <span style={{textTransform:'capitalize'}}>{storeName}</span> before, whether directly or via CashKaro?</Typography><br />
                            <Typography sx={{ color: '#000000', textAlign: 'center', fontSize: '16px' }}>Since <span style={{textTransform:'capitalize'}}>{storeName}</span> offers separate Cashback for first time shoppers and repeat shoppers.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}></Grid>

                        <Grid item xs={6} sm={6} md={6}>
                            <LoadingButton
                                sx={{ width: '100%', textTransform: 'capitalize', borderRadius: '50px', padding: '10px', color:'#F07431',  borderColor:'#F07431'}}
                                // color="secondary"
                                text="Next"
                                variant="outlined"
                                loading={yesBtnLoad}
                                onClick={handleYesButton}
                            >
                                Yes
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <LoadingButton
                                sx={{ width: '100%', textTransform: 'capitalize', borderRadius: '50px', padding: '10px',  color:'#F07431',  borderColor:'#F07431'}}
                                text="Next"
                                variant="outlined"
                                type="submit"
                                loading={noBtnLoad}
                                onClick={handleNoButton}
                            >
                                No
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2} sx={{ textAlign: 'center' }}>
                            <Typography sx={{ color: '#000000', textAlign: 'center', fontSize: '16px' }}>
                                The retailer needs to confirm you as a first time shopper or repeat shopper. Post confirmation, your cashback may increase or decrease
                            </Typography>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            </Grid>
        </Box>
    );

}
