import { useState, useEffect } from "react";
import { UserContext } from "./UserContext";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom"; 
import Loader from "../component/loader"

const UserState = (props) => {
    const navigate = useNavigate()
    const [userData, setUserData] = useState('')
    const [wait, setwait] = useState(true)
    const [cookies] = useCookies(['user']);
    useEffect(() => {
        if (cookies.token) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + cookies.token
                },
            };
            fetch(`${process.env.REACT_APP_SITE_URL}/api/auth/get-agent`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setwait(false)
                    // console.log('from context', data)
                    if ('user' in data) {
                       setUserData(data.user)
                    }else{
                        navigate('/logout')
                    }
                });
        }else{
            setwait(false)
        }
        // eslint-disable-next-line
    }, [])

    return (
        // passing data and function to the context provider
        <UserContext.Provider value={{ userData }}>
            {wait ? <Loader show={wait} margin="30"/> :props.children}
        </UserContext.Provider>
    )
}

export default UserState