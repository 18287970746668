import * as React from "react";
import { useRoutes } from "react-router-dom";
import Index from './pages/index';
import OrderAmount from './pages/orderAmount';
import UserType from './pages/userType';
import ThankYou from './pages/thankyou';
import HighOrderValue from './pages/highOrderValue';
import Error from './pages/error';

import Loader from './component/loader';
export default function  Router() {
  let element = useRoutes([
    
    { path: "/", element: <Index /> },
    { path: "/order-amount", element: <OrderAmount /> },
    { path: "/user-type", element: <UserType /> },
    { path: "/thankyou", element: <ThankYou /> },
    { path: "/high-order-value", element: <HighOrderValue /> },
    { path: "/page-not-found", element: <Error /> },
    // { path: "signup", element: <SignUp /> },
    { path: "loader", element: <Loader /> },
  ]);
  return element;
}