import React, { useEffect, useContext, useState } from "react";
import LoginFormSchema from '../validations/loginFormSchema';
import { useFormik, Form, FormikProvider } from 'formik';
import { TextField, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { useCookies } from 'react-cookie';
import { UserContext } from "../contexts/UserContext";
import AlertMsg from "../component/alert"
import Header from '../layouts/dashboard/header';
import LoadingButton from '@mui/lab/LoadingButton';

import Btn from '../component/btnComponent';
export default function Login() {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext)
    const [cookies, setCookie] = useCookies(['user']);
    const [alert, setAlert] = useState(false)
    const [alertmsg, setAlertMsg] = useState('')
    const [yesBtnLoad, setYesBtnLoad] = useState(false)
    const [noBtnLoad, setNoBtnLoad] = useState(false)


    useEffect(() => {
        let token = cookies.token

        // eslint-disable-next-line
    }, [userData])

    const callApi = (status) => {
        const initialValues = {
            status : status
        }
        console.log(initialValues);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(initialValues)
        };
        // fetch(`${process.env.REACT_APP_SITE_URL}/api/auth/login`, requestOptions)
        //     .then(response => response.json())
        //     .then(data => {
        //         console.log(data)
        //         if ('success' in data) {
        //         setYesBtnLoad(false)

        //             // navigate('/otp', { state: { path: 'login', mobile: initialValues.mobile } });
        //         } else {
        //             // console.log('else', data)
        //         setYesBtnLoad(false)

        //             setAlert(!alert);
        //             setAlertMsg(data.message)
        //         }
        //     })
        
    }
    const handleYesButton = () => {
        setYesBtnLoad(true)
        setNoBtnLoad(false)
        callApi('1');
    }
    const handleNoButton = () => {
        setNoBtnLoad(true)
        setYesBtnLoad(false)
        navigate('/')

    }
    return (
        <Box noValidate autoComplete="off" >
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Header title="" paper="yes" backArrow="no" />
                    <Grid container spacing={3} sx={{ paddingX: '20px', marginTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={12}></Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}></Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}></Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2} sx={{ textAlign: 'center' }}>
                            <Typography sx={{ color: '#000000', textAlign: 'center', fontSize: '16px' }}>
                            Please confirm the order amount paid by you for this order after all the discounts and offers is Rs.10,000 since the this is higher than usual.
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{textAlign:'center'}}>
                            <LoadingButton
                                sx={{ width: '100%', textTransform: 'capitalize', borderRadius: '50px', padding: '10px', color:'#F07431',  borderColor:'#F07431'}}
                                text="Next"
                                variant="outlined"
                                loading={yesBtnLoad}
                                onClick={handleYesButton}
                            >
                                Yes, I Confirm
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{textAlign:'center'}}>
                            <LoadingButton
                                sx={{ width: '100%', textTransform: 'capitalize', borderRadius: '50px', padding: '10px',  color:'#F07431',  borderColor:'#F07431'}}
                                text="Next"
                                variant="outlined"
                                type="submit"
                                loading={noBtnLoad}
                                onClick={handleNoButton}
                            >
                                No, I wish to edit the order value
                            </LoadingButton>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            </Grid>
        </Box>
    );

}
