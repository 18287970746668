import React from "react";
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Header from '../layouts/dashboard/header';

export default function Page() {
  

    return (
        <Box noValidate autoComplete="off" >
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Header title="" paper="yes" backArrow="no" />
                    <Grid container spacing={3} sx={{ paddingX: '20px', marginTop: '20px' }}>
                        <Grid item xs={12} sm={12} md={12} mt={2}></Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}></Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                        <Typography variant="h2" sx={{ color: '#F07431', textAlign: 'center' }}>404</Typography>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h4" sx={{ color: '#000000', textAlign: 'center' }}>Page Not Found</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            </Grid>
        </Box>
    );

}
