import React, { useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from 'formik';
import { TextField, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Header from '../layouts/dashboard/header';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export default function Login() {
    const navigate = useNavigate();
    const [btnLoad, setBtnLoad] = useState(false)
    const [exit_id, setExitID] = useState();
    // const [store, setStore] = useState('');
    const [user_id, setUserID] = useState();
    const [order_id, setOrderID] = useState();
    const [open, setOpen] = React.useState(false);
    const [agree, setAgree] = React.useState(false);
    const [askNew, setAskNew] = useState('');


    const handleClose = () => {
        setOpen(false);
        setBtnLoad(false);
    };

    const callApi = (exit_id) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(`${process.env.REACT_APP_SITE_URL}?exit_id=${exit_id}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if ('data' in data) {
                    setExitID(data.data.exit_id)
                    // setStore(data.data.store)
                    setUserID(data.data.user_id)
                    setOrderID(data.data.order_id)
                    if (data.data.stage === 2) {
                        navigate(`/user-type`)
                    } else if (data.data.stage === 3 || data.data.stage === 4) {
                        navigate(`/thankyou`)
                    } 

                } else {
                    navigate('/page-not-found')
                }
            });
    }
    const callRegx= (store_name, exitId, userId) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(`${process.env.REACT_APP_SITE_URL}/regex?name=${store_name}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.data.length > 0) {
                    let new_data = data.data[0];
                    setExitID(exitId)
                    setUserID(userId)
                    setAskNew(new_data.new_user_screen)
                    
                    callApi(exitId);
                } else {
                    navigate('/page-not-found')
                }
            });
    }
    useEffect(() => {
        let exitId = sessionStorage.getItem('exit_id');
        let store_name = sessionStorage.getItem('store');
        let userId = sessionStorage.getItem('user_id');
        if (exitId !== '' && exitId !== null && store_name !== '' && store_name !== null && userId !== '' && userId !== null) {
               callRegx(store_name, exitId, userId)
        } else {
            navigate('/page-not-found')
        }
// eslint-disable-next-line
    }, [])


    const LoginFormSchema = Yup.object().shape({
        order_value: Yup.number().required('Order amount is required.').typeError('Order amount must be number.'),
    })
    const formik = useFormik({
        initialValues: {
            order_value: '',
            exit_id: exit_id,
            user_id: user_id,
            stage: askNew === 'Yes' ? 2 : 3,
        },
        enableReinitialize: true,
        validationSchema: LoginFormSchema,

        onSubmit: (initialValues) => {
            setBtnLoad(true)
            if (initialValues.order_value > 10000 && agree === false) {
                setOpen(true);
            } else {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(initialValues)
                };
                fetch(`${process.env.REACT_APP_SITE_URL}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        setBtnLoad(false)
                        if ('data' in data) {
                            if(askNew === 'Yes'){
                                navigate(`/user-type`)
                            } else {
                                navigate(`/thankyou`)
                            }
                        }
                    });
            }
            if (agree === true) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(initialValues)
                };
                fetch(`${process.env.REACT_APP_SITE_URL}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        setBtnLoad(false)
                        if ('data' in data) {
                            navigate(`/user-type`)
                        }
                    });
            }
        }
    });

    const { values, errors, touched, handleSubmit, getFieldProps } = formik;
    return (
        <Box noValidate autoComplete="off" >
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Header title="" paper="yes" backArrow="no" />
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={3} sx={{ paddingX: '20px', marginTop: '20px' }}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography sx={{ color: '#000000', textAlign: 'center', fontSize: '20px', fontWeight: 600 }}>Enter the order amount you paid for the order id<br /> <span style={{ color: '#F07431',fontSize:'14px' }}>{order_id}</span></Typography><br /><br />
                                    <Typography sx={{ color: '#000000', textAlign: 'center', fontSize: '15px' }}>Please enter the final order value you paid after all the discounts, coupons & offers etc</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}></Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <TextField
                                            value="₹"
                                            readOnly
                                            sx={{ width: '12%' }}
                                            InputProps={{
                                                readOnly: true,
                                                style: { fontSize: '20px', marign: '-10px' },
                                            }}
                                            helperText=" "
                                        />
                                        <TextField
                                            fullWidth
                                            type="text"
                                            {...getFieldProps('order_value')}
                                            sx={{ width: '85%', marginLeft: '8px' }}
                                            InputProps={{ style: { color: "black", fontSize: '20px' }, autoComplete: 'nope', }}
                                            error={Boolean(touched.order_value && errors.order_value)}
                                            helperText={(touched.order_value && errors.order_value) || ' '}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} mt={2}></Grid>
                                <Grid item xs={12} sm={12} md={12} mt={2} sx={{ textAlign: 'center' }}>
                                    <LoadingButton
                                        sx={{ width: '60%', textTransform: 'capitalize', borderRadius: '50px', padding: '10px', background: '#F07431' }}
                                        // color="secondary"
                                        text="Next"
                                        variant="contained"
                                        type="submit"
                                        loading={btnLoad}
                                        size="small"
                                    // onClick={()=>clickFunc ? clickFunc : ''}
                                    >
                                        Next
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                    
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container spacing={3} sx={{ paddingX: '20px' }}>
                                <Grid item xs={12} sm={12} md={12} mt={2} sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ color: '#000000', textAlign: 'center', fontSize: '16px' }}>
                                        Please confirm the order amount paid by you for this order after all the discounts and offers is Rs. {values.order_value} since the this is higher than usual.
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{ textAlign: 'center' }}>
                                    <LoadingButton
                                        sx={{ width: '100%', textTransform: 'capitalize', borderRadius: '50px', padding: '10px', color: '#F07431', borderColor: '#F07431' }}
                                        text="Next"
                                        variant="outlined"
                                        // loading={yesBtnLoad}
                                        onClick={() => {
                                            setAgree(true)
                                            handleSubmit()
                                        }} autoFocus
                                    >
                                        Yes, I Confirm
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{ textAlign: 'center' }}>
                                    <LoadingButton
                                        sx={{ width: '100%', textTransform: 'capitalize', borderRadius: '50px', padding: '10px', color: '#F07431', borderColor: '#F07431' }}
                                        text="Next"
                                        variant="outlined"
                                        type="submit"
                                        // loading={noBtnLoad}
                                        onClick={handleClose}
                                    >
                                        No, I wish to edit the order value
                                    </LoadingButton>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>

            </Dialog>
        </Box>
    );

}
